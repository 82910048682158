import React from "react";
import styles from "../css/youtube.module.scss";
import Svgsprite from "./svgsprite";
import player from "../images/svg/player.svg";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Poster = (props) => {
  const {
    filename,
    type = "default",
    alt,
    sizes = "(max-width: 400px) 100vw, (max-width: 600px) 100vw,(max-width: 1000px) 100vw",
  } = props;

  const images = useStaticQuery(graphql`
    query Tube {
      data: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "tubeposter" }
        }
      ) {
        edges {
          node {
            relativePath
            default: childImageSharp {
              fluid(quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = images.data.edges.find((n) => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={alt}
      fluid={{
        ...image.node[type].fluid,
        sizes: sizes,
      }}
    />
  );
};

class Youtube extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.posterRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    //console.log(this.posterRef.current.className);
    this.posterRef.current.classList.add(styles["hideposter"]);
    this.iframeRef.current.src = `https://www.youtube.com/embed/${this.props.tubeid}?rel=0&showinfo=0&autoplay=1`;
  }

  render() {
    return (
      <div className={styles.embedbox}>
        <div
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          aria-hidden="true"
          className={styles.poster}
          ref={this.posterRef}
        >
          <Poster alt="Boatpilot video" filename={this.props.posterimagename} />
          <Svgsprite
            svgclass={styles.playbutton}
            name={player.id}
            vbox={player.viewBox}
          />
        </div>
        <iframe
          ref={this.iframeRef}
          className={styles.iframe}
          title="About boatpilot"
          width="100%"
          height="100%"
          src=""
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
          loading="lazy"
        ></iframe>
      </div>
    );
  }
}

export default Youtube;
