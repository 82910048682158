import React, { Fragment } from "react";
import styles from "../css/slider.module.scss";
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  A11y,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Modal from "react-modal";
import Svgsprite from "./svgsprite";
import closeicon from "../images/svg/closeicon.svg";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectFade]);

const Thumb = (props) => {
  const {
    filename,
    type = "default",
    alt,
    //sizes = "(max-width: 400px) 100vw, (max-width: 600px) 100vw,(max-width: 2000px) 100vw, (max-width: 4000px) 100vw",
  } = props;

  const images = useStaticQuery(graphql`
    query Minislider {
      data: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "minislider_tumbs" }
        }
      ) {
        edges {
          node {
            relativePath
            default: childImageSharp {
              fluid(
                sizes: "(max-width: 400px) 100vw, (max-width: 600px) 100vw,(max-width: 2000px) 100vw, (max-width: 4000px) 100vw"
                quality: 85
              ) {
                ...GatsbyImageSharpFluid
              }
            }
            thumb: childImageSharp {
              fluid(maxHeight: 250, quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = images.data.edges.find((n) => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={alt}
      fluid={{
        ...image.node[type].fluid,
        // sizes: sizes,
      }}
    />
  );
};

class Minislider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedImage: null,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) this.handleCloseModal();
    });
  }

  render() {
    const thumbFileNames = this.props.names;

    const thumbItems = thumbFileNames.map((galleryName, index) => (
      <SwiperSlide
        key={index}
        onClick={() => this.setState({ showModal: true, selectedImage: index })}
      >
        <div className="slidewrap">
          <Thumb type="thumb" alt="" filename={galleryName} />
        </div>
      </SwiperSlide>
    ));

    const fullImageItems = thumbFileNames.map((galleryName, index) => (
      <SwiperSlide key={index}>
        <div className="slidewrap">
          <Thumb alt="" filename={galleryName} />
        </div>
      </SwiperSlide>
    ));

    return (
      <Fragment>
        <div className="smallslider">
          <Swiper
            spaceBetween={0}
            slidesPerView={this.props.slidetoview || "3"}
            navigation={this.props.isshownav}
            loop
          >
            {thumbItems}
          </Swiper>
        </div>
        <Modal
          shouldFocusAfterRender={false}
          closeOnEscape={true}
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <div className="fullimageslider">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              initialSlide={this.state.selectedImage}
              navigation
              EffectFade
              loop
            >
              {fullImageItems}
            </Swiper>
          </div>
          <button
            className={styles.closebutton}
            onClick={this.handleCloseModal}
          >
            <Svgsprite
              svgclass={styles.closeicon}
              name={closeicon.id}
              vbox={closeicon.viewBox}
            />
          </button>
        </Modal>
      </Fragment>
    );
  }
}

Minislider.defaultProps = {
  isshownav: true,
};

export default Minislider;
